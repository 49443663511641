import React, {useState, useEffect} from 'react';
import {Location} from '@reach/router';
import {Col, Container, Row, Button} from 'reactstrap';
import PageMeta from 'components/PageMeta';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ShareButtons from 'components/ShareButtons';
import ResourceTabs, {ResourceTabNav} from 'components/Resources/ResourceTabs';

export default (props) => (
  <Location>
    {({location}) => {
      const addProps = {};
      if (location && location.hash) {
        const matches = location.hash.match(/^#tab-(\d)/);
        if (matches && matches.length === 2) addProps.activePanel = parseInt(matches[1]) - 1;
      }
      return <Resources {...props} {...addProps} location={location} />;
    }}
  </Location>
);

function Resources({location, ...props}) {
  const [activePanel, setActivePanel] = useState(props.activePanel || 0);
  useEffect(() => setActivePanel(props.activePanel), [props.activePanel]);

  return (
    <div className="page d-flex flex-column">
      <PageMeta
        title="Plant-Powered & Thriving Resource Kit Videos"
        description="Make FOOD the foundation of YOUR health"
      />

      <div className="page-content">
        <Header className="background-black header-wrap" logoWhite style="dark" />
        <section className="section-broadcast background-gradient-indigo-purple-right text-white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-only-heading text-center">Resources</h2>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-resources">
          <div className="section-inner-noangle">
            <Container>
              <Row>
                <Col lg="8">
                  <ResourceTabs activePanel={activePanel} />
                </Col>
                <Col lg="4">
                  <div className="resource-tab-wrap">
                    <ResourceTabNav setActivePanel={setActivePanel} activePanel={activePanel} />
                  </div>

                  <div className="resource-ep background-gradient-indigo-purple-right p-4 rounded text-white text-center">
                    <h6 className="section-heading">Make healthy eating an irresistible habit you love.</h6>
                    <p>
                      Join the <em>Plant-Powered & Thriving</em> course today.
                    </p>
                    <img
                      className="background-white p-1 rounded mb-3"
                      src="https://cdn.foodrevolution.org/ppt/ppt-product-image.png"
                      width="1200"
                      height="500"
                      alt="Plant-Powered & Thriving graphic"
                    />
                    <Button color="cta" href="/join" target="_blank" block>
                      Learn More
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>

      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons floating />
      </div>
    </div>
  );
}
