import React, {Component} from 'react';
import {Button, Card, Col, Container, Row} from 'reactstrap';
import PageMeta from 'components/PageMeta';
import OptInFormInfographic from 'components/OptInFormInfographic';
import ShareButtons from 'components/ShareButtons';
import Footer from 'components/Footer';
import Header from 'components/Header';

export default function InfographicIndex() {
  const shareUrl = 'https://thriving.foodrevolution.org/longevity/';
  const shareImg = 'https://cdn.foodrevolution.org/ppt/social/ppt-infographic-ogimage-20191004.jpg';
  const facebookDescription =
    'Renowned nutritional expert and bestselling author John Robbins has helped millions of people to step into greater health and wellness. Get his top ten foods to eat, and foods to avoid, for longevity in a handy printable infographic poster. Download it here...';
  const twitterDescription =
    'Do you know the foods you should include in your #diet every day? Some of the items on the list surprised me, and I wonder if they’ll surprise you! https://twitter.com/AFoodRevolution/status/1187109947710025731?s=20 Take a peek here:';
  const twitterHashtags = ['longevity', 'infographic', 'healthyliving'];
  const pinterestDescription =
    'This awesome tool shows the top ten foods that have been proven to prevent - or promote - heart disease, cancer, type 2 diabetes, Alzheimer’s, and osteoporosis. Visit https://thriving.foodrevolution.org/longevity/ to download it for free!';
  const pinterestImg = 'https://cdn.foodrevolution.org/ppt/social/ppt-infographic-ogimage-20191004.jpg';

  return (
    <div className="infographic-optin-background fullsize background-cover background-fixed background-image-center">
      <PageMeta
        url={shareUrl}
        title="Download your Foods To Eat And Avoid infographic poster!"
        description="John Robbins, best-selling author and nutritional expert, has helped millions of people to step into greater health and wellness. Now, he’s condensed highlights from decades of research and advocacy into a handy, printable infographic poster. Download it now!"
        ogImage={shareImg}
      />

      <div className="page infographic-optin-page d-flex flex-column">
        <Header
          className="background-purple header-wrap"
          logoWhite
          style="dark"
          shareUrl={shareUrl}
          shareImg={shareImg}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterHashtags={twitterHashtags}
          pinterestDescription={pinterestDescription}
          pinterestImg={pinterestImg}
        />
        <Container className="infographic-optin-container">
          <Row className="d-flex justify-content-center">
            <Col xs="12" lg="8" className="p-0">
              <div className="infographic-optin-box background-white rounded">
                <div className="infographic-optin-copy text-center">
                  <Row>
                    <Col>
                      <h4 className="section-heading">
                        Discover John Robbins’ <br className="d-none d-lg-block" />
                        <em>
                          Top 10 Foods <br className="d-none d-sm-block d-lg-none" />
                          To Eat And <br className="d-none d-lg-block" />
                          Avoid For Longevity
                        </em>
                      </h4>
                      <p className="mb-0">
                        Enter your information below to get this handy infographic that{' '}
                        <br className="d-none d-sm-block d-lg-none" />
                        you can even print and put on <br className="d-none d-lg-block" />
                        your fridge — so you never forget!
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="infographic-optin-form">
                  <OptInFormInfographic linkColor="black" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="footer-content mt-auto mt-lg-0">
          <Footer />
          <ShareButtons
            shareUrl={shareUrl}
            shareImg={shareImg}
            facebookDescription={facebookDescription}
            twitterDescription={twitterDescription}
            twitterHashtags={twitterHashtags}
            pinterestDescription={pinterestDescription}
            pinterestImg={pinterestImg}
            floating
          />
        </div>
      </div>
    </div>
  );
}
