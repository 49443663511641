import React, {useCallback, useEffect, useState} from 'react';
import {Location} from '@reach/router';
import queryString from 'query-string';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {useUserState} from 'contexts/user';
import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import Header from 'components/Header';
import {SectionTriangle, SectionTriangleSpeakerCollapse} from 'components/Elements/Section';
import OptinModal, {OptinModalTrigger} from 'components/OptinModal';
import MasterclassInfographicForm from 'components/MasterclassInfographicForm';
import Video from 'components/Video';
import {ListIcon} from 'components/Elements/IconList';
import {TestimonialBubble} from 'components/Testimonial';
import ShareButtons from 'components/ShareButtons';
import Footer from 'components/Footer';

export default function (props) {
  return (
    <Location>
      {({location, navigate}) => <MasterClassIndex location={location} navigate={navigate} {...props} />}
    </Location>
  );
}
function MasterClassIndex({
  location = {},
  noShare = false,
  noFixedVideo = true,
  linkLogo = false,
  slug = 'masterclass',
  navigate,
  variant = 'default',
  fromEntry = 'Masterclass',
  pageTitle = 'Join the Food For Health Masterclass with John Robbins',
  pageDescription = 'Unlock your unique body’s ultimate potential through this Masterclass with John Robbins where he talks about how you can make food the foundation of YOUR health with these 10 mighty plant-powered breakthroughs. This Masterclass is only available for a limited time, so register today!',
  shareUrl = 'https://thriving.foodrevolution.org/masterclass/',
  shareImg = 'https://cdn.foodrevolution.org/ppt/social/ppt-ogimage-v20191018.jpg',
  facebookDescription = 'John Robbins is one of the world’s most beloved natural health experts and inspired millions of people to claim the power of their food choices to take a stand for a healthy life. Right now, you have a very special limited-time opportunity to join John for a totally free Masterclass on how you can make food the foundation of YOUR health. Click here to join him...',
  twitterDescription = 'Find out about 10 mighty #plantpowered breakthroughs that could save millions of lives in this free #masterclass with world-renowned author, John Robbins. Join the FREE #foodforhealthmasterclass here:',
  twitterHashtags = [null],
  twitterImg = 'https://cdn.foodrevolution.org/ppt/social/ppt-ogimage-v20191018.jpg',
  pinterestDescription = 'Looking for a sign that it’s time to take charge of your healthy-eating habits? This is it. Join this free masterclass and get the inspiration you need to make your healthy habits stick. Watch here now: https://thriving.foodrevolution.org/masterclass/',
  pinterestImg = 'https://cdn.foodrevolution.org/ppt/social/ppt-ogimage-v20191018.jpg',
  ...props
}) {
  const {email} = useUserState();
  const qs = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {};
  // @ts-ignore
  const {fromInfographic} = qs.fromInfographic ? qs : location.state || {};
  const navigateWatch = useCallback(() => navigate('/masterclass/watch/'), []);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const pathName = window.location.pathname;
      const pathRegex = new RegExp(`\/${slug}\/`, 'g');
      if (!pathName.match(pathRegex)) {
        // @ts-ignore
        navigate(`/${slug}/` + location.search);
      }
    }
  }, []);

  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  function Logo({className = null, src = 'https://cdn.foodrevolution.org/ppt/ppt-masterclass-logo-purpleblack.svg'}) {
    return <img src={src} alt="food for health masterclass logo" style={{maxHeight: '90px'}} className={className} />;
  }

  const optinButtonText = 'Reserve My Spot';
  const ctaButton =
    fromInfographic && email ? (
      <MasterclassInfographicForm fromInfographic={fromInfographic} />
    ) : (
      <OptinModalTrigger>{fromEntry === 'plants' ? <>Yes! Sign Me Up!</> : optinButtonText}</OptinModalTrigger>
    );

  useBalanceText();
  return (
    <div className={classNames(`page d-flex flex-column ${noShare ? 'page-nosocial' : null}`)}>
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={shareImg} />

      <div className="page-content">
        {fromInfographic && (
          <section className="section-infographic-info background-green text-white py-4">
            <Container>
              <Row className="d-flex align-items-center">
                <Col xs="3" md="2" className="text-center">
                  <img
                    className="img-infographic img-fluid"
                    src="https://cdn.foodrevolution.org/ppt/frn-infographic-foodslongevity.png"
                  />
                </Col>
                <Col xs="9" md="10">
                  <p>
                    Thanks for taking an interest in food and your health! Your infographic of{' '}
                    <i>10 Foods to Eat and 10 Foods to Avoid for Health and Longevity</i> should be in your email inbox
                    in a few minutes. <b>Now take the next step. Learn the secrets to plant-powered thriving.</b>
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
        )}

        <Header
          className="background-white header-wrap"
          style="light"
          linkLogo={linkLogo}
          shareUrl={shareUrl}
          shareImg={shareImg}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImg={pinterestImg}
        />

        <SectionTriangle
          id="landing-header-v1b"
          color="black"
          innerClass="p-0 my-auto"
          className={classNames(`d-flex`, `flex-column`, `landing-header-optin-color`)}
        >
          <Container>
            <Row>
              <Col>
                <div className="landing-header-optin-headline text-white balance-text">
                  <b>Transform</b> the way you{' '}
                  <b>
                    <i>think, eat,</i>
                  </b>{' '}
                  and{' '}
                  <b>
                    <i>live.</i>
                  </b>
                </div>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>
        <SectionTriangle id="" color="purple" type="none" innerClass="py-4">
          <Container>
            <Row>
              <Col>
                <p className="text-600 text-center text-white balance-text m-0">
                  <i>
                    Now Playing <b>FREE</b> For A Limited Time!
                  </i>
                </p>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>
        <SectionTriangle id="landing-header-v2b" color="white" innerClass="text-center pt-5" type="none">
          <Container>
            {fromEntry === 'plants' ? (
              <Row className="align-items-center justify-content-center">
                <Col xs="12" lg="7">
                  <p>
                    Plant-based eating is the #1 diet for <br className="d-none d-lg-block" />
                    your health and for the planet. But some <br className="d-none d-xl-block" />
                    people are doing it wrong and getting sick.
                  </p>
                  <p>
                    Find out how to do it right — so <br className="d-none d-lg-block d-xl-none" />
                    you can reap the benefits!
                  </p>{' '}
                  <h3 className="mt-0">
                    Get the free <i>Guide To Going Plant-Based</i> AND Get A Free Spot in the Food For Health
                    Masterclass
                  </h3>
                  <p>
                    In this FREE Masterclass, John Robbins <br className="d-none d-lg-block d-xl-none" />
                    reveals <br className="d-none d-sm-block d-lg-none d-xl-block" />
                    10 mighty, plant-powered breakthroughs that can{' '}
                    <br className="d-none d-sm-block d-lg-none d-xl-block" />
                    unlock your <br className="d-none d-lg-block d-xl-none" />
                    unique body’s ultimate health potential.
                  </p>
                </Col>
                <Col xs="12" lg="5">
                  <div className="img-guidebook mb-4">
                    <img
                      src="https://cdn.foodrevolution.org/global/guidebook/guide-to-going-plant-based.png"
                      style={{maxHeight: '300px'}}
                    />
                  </div>
                  {ctaButton}
                </Col>
              </Row>
            ) : (
              <Row className="justify-content-center">
                <Col lg="10">
                  <Logo />
                  <p className="balance-text my-4">
                    In this <b>FREE Masterclass,</b> John Robbins reveals 10 mighty, plant-powered breakthroughs that
                    can unlock your unique body’s{' '}
                    <b>
                      <i>ultimate health potential.</i>
                    </b>
                  </p>
                  {ctaButton}
                </Col>
              </Row>
            )}
          </Container>
        </SectionTriangle>

        <SectionTriangle
          id="landing-video"
          color="white"
          className={classNames(`text-center`, `section-triangle-offset-top-none`)}
          type="bottom"
        >
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <img
                  className="w-100"
                  src="https://cdn.foodrevolution.org/ppt/video-thumb/ppt-thumb-240423-v1-noclick.gif"
                  alt="animated graphic with John that says health is your greatest wealth"
                />
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="black" type="bottom" className="text-center text-white">
          <Container>
            <Row>
              <Col>
                <h3 className="section-only-heading text-400">
                  In This <b>80-Minute Masterclass,</b> Get Ready For:
                </h3>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="white" type="bottom">
          <Container>
            <Row>
              <Col>
                <ListIcon iconColor="black" className="m-0">
                  <>
                    <h5 className={`mt-0 mb-1`}>The big food lies</h5>
                    <p>
                      The food industry relies on some pretty dark myths they’d rather we didn’t know. John will shed
                      light on the <b>most dangerous food lies to stop buying into right now.</b>
                    </p>
                  </>
                  <>
                    <h5 className={`mt-0 mb-1`}>10 mighty plant-powered breakthroughs</h5>
                    <p>
                      John shares 10 powerful breakthroughs that are{' '}
                      <b>
                        <i>super easy to implement,</i>
                      </b>{' '}
                      and that can{' '}
                      <b>
                        <i>help us live our healthiest life</i>
                      </b>{' '}
                      while reducing stress and increasing happiness.
                    </p>
                  </>
                  <>
                    <h5 className={`mt-0 mb-1`}>How what we eat, and what we don’t eat, can help save the planet</h5>
                    <p>
                      Ever feel concerned about the state of the world? Want to be part of the solution? Good news! Our
                      food choices can help save topsoil so our grandkids can grow food, save animals from cruelty, and
                      protect the climate of our planet.
                    </p>
                  </>
                  <>
                    <h5 className={`mt-0 mb-1`}>John’s inspiring story</h5>
                    <p>
                      John shares how he walked away from inheriting the Baskin-Robbins ice cream empire and went on to
                      be considered one of the most powerful spokespersons in the world for a sane, ethical, and
                      sustainable future.
                    </p>
                  </>
                  <>
                    <h5 className={`mt-0 mb-1`}>Why taking control of health matters</h5>
                    <p>
                      Get inspired like never before to <b>take YOUR health to the next level.</b>
                    </p>
                  </>
                  <>
                    <h5 className={`mt-0 mb-1`}>Receive a special invitation</h5>
                    <p>
                      Gain access to John and Ocean’s landmark online course, Plant-Powered & Thriving, for a massive
                      discount. Stay to the end to find out all about it.
                    </p>
                  </>
                </ListIcon>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="black" type="bottom" className="text-center text-white">
          <Container>
            <Row>
              <Col>
                <h3 className="section-only-heading">Meet Your Instructor</h3>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>
        <SectionTriangleSpeakerCollapse
          id=""
          // @ts-ignore
          imgUrl={require('static/speaker/john-robbins-veggies-square.jpg')}
          imgPosition="right"
          color="white"
          type="bottom"
          headlines={
            <>
              <h2 className="section-heading mb-2">John Robbins</h2>
              <h4 className="m-0 text-400">
                One of the world’s most beloved experts on living a plant-powered lifestyle.
              </h4>
            </>
          }
          collapseHeader={
            <>
              <h5 className="text-400 m-0">Click Here to</h5>
              <h4 className="m-0">Learn More About John Robbins</h4>
            </>
          }
          collapseHeaderBgColor="black"
          content={
            <>
              <p>
                John Robbins was groomed to be the heir to the Baskin-Robbins ice cream empire, which was founded and
                owned by his father. He had money, prestige, and security, along with an ice cream cone-shaped swimming
                pool in the backyard.
              </p>
              <p>
                So why did John walk away from a path that was practically “paved with gold and ice cream”? Because his
                conscience was emerging. He didn’t want to devote his life to selling ice cream after realizing it makes
                people unhealthy. So, he decided to make a change and forge a new path.
              </p>
              <p>
                Over the last 35 years, John’s books about healthy eating and healthy living (including the blockbuster
                bestseller, <i>Diet for a New America</i>) have sold millions of copies and been translated into more
                than 30 languages. He has made hundreds of local and national radio and television appearances, spoken
                in person to hundreds of thousands of people, founded the nonprofit, EarthSave International, and
                received dozens of awards for his work on behalf of healthy people and a healthy planet.
              </p>
              <p>With his son and colleague, Ocean Robbins, John serves as co-host of Food Revolution Network.</p>
            </>
          }
        />

        <SectionTriangle id="" color="light-gray" type="bottom" innerClass="mobile-padding">
          <Container>
            <Row>
              <Col>
                <h4 className="section-heading text-center mb-4">
                  Here’s what some of the world’s leading figures have said about John Robbins.
                </h4>
                <TestimonialBubble className="mb-4" bgColor="white" id="woody-harrelson-v2" />
                <TestimonialBubble className="mb-4" bgColor="white" id="paul-hawken-v2" />
                <TestimonialBubble bgColor="white" id="kris-carr-v2" />
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="black" type="bottom" className="text-center text-white">
          <Container>
            <Row>
              <Col>
                <h3 className="section-only-heading">Meet Your Host</h3>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>
        <SectionTriangleSpeakerCollapse
          id=""
          // @ts-ignore
          imgUrl={require('static/speaker/57.jpg')}
          imgPosition="left"
          color="white"
          type="bottom"
          headlines={
            <>
              <h2 className="section-heading mb-2">Ocean Robbins</h2>
              <h4 className="m-0 text-400">A food revolution leader and inspiration to millions.</h4>
            </>
          }
          collapseHeader={
            <>
              <h5 className="text-400 m-0">Click Here to</h5>
              <h4 className="m-0">Learn More About Ocean Robbins</h4>
            </>
          }
          collapseHeaderBgColor="black"
          content={
            <>
              <p>
                Ocean Robbins was born in a log cabin built by his parents and was raised on food they grew on the land
                together. He has reached millions of people from 180+ nations in schools, conferences, and online
                events.
              </p>
              <p>
                Ocean’s work has taken him all over the world, where he has seen first-hand the powerful impact of the
                food we eat — not just on our health, but on people and economies everywhere. He has served as an
                adjunct professor for Chapman University and is author of the national bestseller,{' '}
                <i>31-Day Food Revolution.</i>
              </p>
              <p>He serves as CEO of Food Revolution Network.</p>
            </>
          }
        />

        <SectionTriangle id="" color="light-gray" type="none" innerClass="text-center">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-purple mb-2">
                  <i>Don’t Miss Your Chance!</i>
                </h2>
                <h3 className="m-0">For a limited time watch the</h3>
                <Logo className="my-5" />
                <div>{ctaButton}</div>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>
      </div>
      <div className="footer-content my-auto">
        <Footer />
        {noShare ? null : (
          <ShareButtons
            pageDescription={pageDescription}
            shareUrl={shareUrl}
            shareImg={shareImg}
            facebookDescription={facebookDescription}
            twitterDescription={twitterDescription}
            pinterestDescription={pinterestDescription}
            pinterestImg={pinterestImg}
            floating
          />
        )}
      </div>

      <OptinModal entry={fromEntry} />
    </div>
  );
}
