import React, {useEffect, useState} from 'react';
import {Col, Container, Input, Row} from 'reactstrap';
import classNames from 'classnames';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import track from 'utils/track';
import PageMeta from 'components/PageMeta';
import Header from 'components/Header';
import Section, {SectionImage} from 'components/Elements/Section';
import ShareButtons from 'components/ShareButtons';
import Footer from 'components/Footer';
import IconList, {IconListItem} from 'components/Elements/IconList';
import CheckoutButton from 'components/CheckoutButton';
import {PurchaseCard, SalesDisclaimer} from 'components/SalesCta';
import {ImgBulletCard} from 'components/Elements/ImgBullet';
import {TestimonialCircle} from 'components/Testimonial';
import {GuaranteeBox} from 'components/Guarantee';
import {ModuleList} from 'components/ModuleAccordion';
import {fdaModules} from 'data/fda.json';
import CountdownTimer from 'components/CountdownTimer';
import HeaderVideo from 'components/Elements/HeaderVideo';
import ModalExitFDA from 'components/Modals/ModalExitFDA';

function AcceleratorSales({slug, ...props}) {
  return (
    <PurchaseCard
      className="offer-7"
      regularPrice="47"
      offerPrice="7"
      sku="A-FDA"
      slug={slug}
      title={
        <h3 className="section-only-heading">
          What’s Included with the <br className="d-none d-md-block" />
          14-Day Plant-Powered Accelerator?
        </h3>
      }
      copy={
        <>
          <div className="list-simple-check-wrap px-md-4">
            <IconList className="text-base">
              <IconListItem>
                14 simple but essential lessons <br className="d-none d-sm-block d-md-none d-xl-block" />
                (a new one delivered to your inbox <br className="d-none d-sm-block d-md-none d-xl-block" />
                every day for two weeks)
              </IconListItem>
              <IconListItem>
                Make consistent progress and see results <br className="d-none d-sm-block d-md-none" />
                with these 14 action steps
              </IconListItem>
              <IconListItem>
                14 crave-worthy (and supremely nourishing) recipes to launch your healthier life
              </IconListItem>
              <IconListItem>
                Links to 72 of our most popular, useful, and <br className="d-none d-sm-block" />
                fact-based articles (which link to{' '}
                <b>
                  <i>dozens</i>
                </b>{' '}
                more fabulous recipes and hundreds of cited <br className="d-none d-sm-block" />
                medical journal articles)
              </IconListItem>
            </IconList>
          </div>
          <p className="text-center mt-4">
            Special offer for Food For Health <br className="d-none d-sm-block" />
            Masterclass participants only:
          </p>
          <h4 className="text-center mt-0 mb-2">
            Your Price: <del>$47</del>
          </h4>
          <h2 className="text-center text-green mt-0 mb-2">JOIN NOW for only $7</h2>
          <h5 className="text-center text-400 mt-0">(Save 85%!)</h5>
        </>
      }
      noImage
      {...props}
    />
  );
}

function AcceleratorSalesFull(props) {
  return (
    <PurchaseCard
      className="offer-47"
      regularPrice="47"
      sku="A-FDA"
      slug="fda"
      title={
        <h3 className="section-only-heading">
          What’s Included with the <br className="d-none d-md-block" />
          14-Day Plant-Powered Accelerator?
        </h3>
      }
      copy={
        <>
          <div className="list-simple-check-wrap px-md-4">
            <IconList className="text-base">
              <IconListItem>
                14 simple but essential lessons <br className="d-none d-sm-block d-md-none d-xl-block" />
                (a new one delivered to your inbox <br className="d-none d-sm-block d-md-none d-xl-block" />
                every day for two weeks)
              </IconListItem>
              <IconListItem>
                Make consistent progress and see results <br className="d-none d-sm-block d-md-none" />
                with these 14 action steps
              </IconListItem>
              <IconListItem>
                14 crave-worthy (and supremely nourishing) recipes to launch your healthier life
              </IconListItem>
              <IconListItem>
                Links to 72 of our most popular, useful, and <br className="d-none d-sm-block" />
                fact-based articles (which link to{' '}
                <b>
                  <i>dozens</i>
                </b>{' '}
                more fabulous recipes and hundreds of cited <br className="d-none d-sm-block" />
                medical journal articles)
              </IconListItem>
            </IconList>
          </div>
          <p className="text-center mt-4">
            Special offer for Food For Health <br className="d-none d-sm-block" />
            Masterclass participants only:
          </p>
          <h2 className="text-center text-green mt-0 mb-2">Your Price: $47</h2>
        </>
      }
      noImage
      {...props}
    />
  );
}

export default function SalesIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = '',
  ctaNoTimer = false,
  variant = null,
  ...props
}) {
  const {bucket, email, registrationId} = useUserState();
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      },
      sales: salesSchedule = {
        phase: 'during',
        salesEnd: '',
        isSalesDay: false
      },
      webinar: webinarSchedule = {
        phase: 'before',
        time: ''
      }
    }
  } = usePhaseState();

  const salesFullPrice =
    (!email && !registrationId && !ctaOverride) ||
    (!ctaOverride && webinarSchedule.phase === 'after') ||
    ctaOverride === 'fullPrice';

  const optinTime = webinarSchedule.time * 1000;
  const cartOpenTime = webinarSchedule.cartOpenPeriod * 1000;
  const downSellTime = webinarSchedule.downSellPeriod * 1000;
  const salesEnd = new Date(optinTime + cartOpenTime + downSellTime).getTime();

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'A-FDA',
      sku: 'A-FDA',
      name: '14-Day Plant-Powered Accelerator',
      variant: salesFullPrice ? 'fda-special' : 'fda',
      value: salesFullPrice ? 7 : 47,
      currency: 'usd'
    });
  }, [salesFullPrice]);

  const btnText = 'Buy Now';

  function CtaSalesBox(props) {
    return (
      <>
        {ctaOverride || !salesFullPrice ? (
          <>
            {ctaOverride === 'salesPrice' || !salesFullPrice ? (
              <>
                {ctaNoTimer ? (
                  <AcceleratorSales slug={!salesFullPrice ? 'fda-special' : 'fda'} btnText={btnText} />
                ) : (
                  <AcceleratorSales
                    slug={!salesFullPrice ? 'fda-special' : 'fda'}
                    date={salesEnd}
                    timerText="Your discount expires in:"
                    btnText={btnText}
                  />
                )}
              </>
            ) : (
              <AcceleratorSalesFull btnText={btnText} />
            )}
          </>
        ) : (
          <AcceleratorSalesFull btnText={btnText} />
        )}
      </>
    );
  }

  return (
    <div className="page d-flex flex-column">
      <PageMeta
        title="Get the 14-Day Plant-Powered Accelerator Today!"
        description="If you want to accelerate your healthy eating journey, this course is for you. Get 14 days of research-backed and action-inspiring lessons and recipes that will set you up for plant-powered success."
      />
      <div className="page-content">
        <Header className="background-purple header-wrap" logoWhite style="dark" />

        <Section id="header-cta" className="section-angle-first" color="black" innerClass="text-white text-center">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xs="12" lg="10" className="video-col px-0 px-lg-3 mb-5">
                <HeaderVideo
                  url={
                    !salesFullPrice
                      ? 'https://www.youtube.com/watch?v=8n4zRflU2gk'
                      : 'https://www.youtube.com/watch?v=9NVl4-ZdrNk'
                  }
                  headline={
                    <h3 className="section-heading">
                      Get the 14-Day <br className="d-none d-lg-block" />
                      Plant-Powered Accelerator for just:{' '}
                      {!salesFullPrice ? (
                        <>
                          <del>$47</del> $7
                        </>
                      ) : (
                        <>$47</>
                      )}
                    </h3>
                  }
                  label="FDA - Sales"
                  noFixed={noFixedVideo}
                  sku="A-FDA"
                  slug={!salesFullPrice ? 'fda-special' : 'fda'}
                  price={!salesFullPrice ? '7' : '47'}
                  thumbnail="https://cdn.foodrevolution.org/fda/video-thumb/fda-video-thumbnail-sales-page-making-smoothie-computer-202201.jpg"
                  salesBtnText={btnText}
                  sales
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  Special offer for Food For <br className="d-none d-sm-block d-md-none" />
                  Health Masterclass participants only:
                </p>
                {!salesFullPrice ? (
                  <>
                    <h2 className="section-heading mb-2">
                      Get the 14-Day <br className="d-none d-sm-block d-lg-none" />
                      Plant-Powered <br className="d-none d-xl-block" />
                      Accelerator now for just: <del>$47</del> $7
                    </h2>
                    <h5 className="mt-0 text-400">(85% savings!)</h5>
                    {ctaNoTimer ? null : (
                      <div className="cta-timer mb-3">
                        <div className="offer-box-timer-text">Your special discount expires in:</div>
                        <CountdownTimer time={salesEnd} />
                      </div>
                    )}
                    <CheckoutButton sku="A-FDA" slug="fda-special" price="7">
                      {btnText}
                    </CheckoutButton>
                  </>
                ) : (
                  <>
                    <h2 className="section-heading mb-4">Get the 14-Day Plant-Powered Accelerator now for just: $47</h2>
                    <CheckoutButton sku="A-FDA" slug="fda" price="47">
                      {btnText}
                    </CheckoutButton>
                  </>
                )}
                <SalesDisclaimer iconColor="white" />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage id="fda-reading" color="white" imgPosition="left" imgUrl={require('static/fda/1136640820.jpg')}>
          <h3 className="section-heading">
            Keep reading <br className="d-none d-lg-block d-xl-none" />
            if you want to:
          </h3>
          <IconList>
            <IconListItem>
              Become a <b>healthier person</b> — no pricey supplements required.
            </IconListItem>
            <IconListItem>
              <b>Instantly expand</b> your energy, in a 100% natural way that takes as little as five minutes a day.
              (Many people in their 100s swear by this!)
            </IconListItem>
            <IconListItem>
              <b>Avoid</b> unnecessary medications, doctors’ visits, and surgeries that take weeks or months to recover
              from.
            </IconListItem>
            <IconListItem>
              <b>Effortlessly</b> switch from life-zapping foods to vibrant, life-giving foods.
            </IconListItem>
            <IconListItem>
              Adopt iron-clad <b>motivation methods</b> so you pick healthy options no matter where you are or how
              hungry you are — even at a party or picnic.
            </IconListItem>
          </IconList>
        </SectionImage>

        <Section id="fda-necessity" color="light-gray">
          <Container>
            <Row className="justify-content-center">
              <Col xl="11" className="text-center">
                <h3 className="section-heading">
                  We created this course <br className="d-none d-sm-block d-lg-none" />
                  out of necessity.
                </h3>
                <p>
                  After years of watching well-intentioned people attempt to completely{' '}
                  <br className="d-none d-lg-block" />
                  overhaul their diets only to crash a few weeks later, we realized that{' '}
                  <br className="d-none d-lg-block" />
                  small steps taken in the right way are what lead to LASTING change.
                </p>
                <p>
                  That’s exactly what the 14-Day Plant-Powered Accelerator{' '}
                  <br className="d-none d-lg-block d-xl-none" />
                  is designed to fuel — tiny, <br className="d-none d-xl-block" />
                  daily actions that are fun, flavorful, <br className="d-none d-lg-block d-xl-none" />
                  and make a huge impact on health (deliciously)!
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col xl="10">
                <h5 className="section-heading">Discover how easy it is to:</h5>
                <div className="text-h4">
                  <ImgBulletCard img={require('static/fda/1141343601.jpg')}>Do a good deed for health</ImgBulletCard>
                  <ImgBulletCard img={require('static/fda/1241736799.jpg')}>
                    Become a positive influence on loved ones
                  </ImgBulletCard>
                  <ImgBulletCard img={require('static/fda/1126508104.jpg')}>Reduce animal cruelty</ImgBulletCard>
                  <ImgBulletCard img={require('static/fda/1278911675.jpg')}>Help a struggling planet</ImgBulletCard>
                </div>
                <h5 className="mt-4 text-center">...just by making a few easy choices.</h5>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="fda-testimonials-1" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">
                  Here’s What Food Revolution Network’s Members Say About Adding in More Plant-Based Whole Foods:
                </h2>
                <p className="text-center text-16 mb-0">
                  <em>
                    These testimonials reflect people’s unique experience. <br className="d-none d-sm-block" />
                    Your specific experience will vary.
                  </em>
                </p>
                <hr className="my-5" />
                <TestimonialCircle id="fda-donna-b" />
                <hr className="my-5" />
                <TestimonialCircle id="fda-eric-c" imgPosition="right" />
                <hr className="my-5" />
                <TestimonialCircle id="fda-terri-c" />
                <hr className="my-5" />
                <TestimonialCircle id="fda-margaret-s" imgPosition="right" />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage id="fda-win" color="light-gray" imgPosition="left" imgUrl={require('static/fda/1339027651.jpg')}>
          <h3 className="section-heading">
            These are just a tiny fraction of <br className="d-none d-xl-block" />
            the phenomenal wins we’ve seen when people adopt a whole foods, plant-based lifestyle.
          </h3>
          <p>
            For over 10 years, we’ve witnessed it all: tears of joy, lives renewed, relationships saved, doctors
            astonished, total health turnarounds, revived hope, new leases on life…
          </p>
          <p>
            Inside the <b>14-Day Plant-Powered Accelerator,</b> we help people become energy-filled, health-conscious,
            compassionate, enthusiastic natural foods eaters — capable of outshining their former selves and shattering
            their old ideas of what it means to get older.
          </p>
          <p>
            What’s the deal? Why is the <b>14-Day Plant-Powered Accelerator</b> so outstanding for people looking to
            jumpstart an entirely new way of life?
          </p>
          <p>
            Because it’s so{' '}
            <b>
              <i>simple.</i>
            </b>
          </p>
        </SectionImage>

        <Section id="fda-intro" color="white">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" className="text-center">
                <p className="mb-2">INTRODUCING THE</p>
                <img
                  className="mb-3"
                  src="https://cdn.foodrevolution.org/fda/fda-logo.svg"
                  style={{maxHeight: '100px'}}
                />
                <p>
                  <i>Fast Track Your Plant-Powered Health</i>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="fda-secrets"
          color="white"
          imgPosition="right"
          imgUrl={require('static/fda/1210530744.jpg')}
          title={
            <h2 className="section-only-heading text-center">
              With the 14-Day <br className="d-none d-md-block d-lg-none" />
              Plant-Powered Accelerator, <br className="d-none d-md-block d-lg-none d-xl-block" />
              get another healthy eating secret delivered straight to your{' '}
              <br className="d-none d-lg-block d-xl-none" />
              inbox every day!
            </h2>
          }
          titleClass="mb-3"
        >
          <IconList>
            <IconListItem>
              <b>Breathe new life</b> into your kitchen routine.
            </IconListItem>
            <IconListItem>
              Skyrocket your know-how — and your <b>confidence</b> that you CAN eat healthier.
            </IconListItem>
            <IconListItem>
              Cut through the nutrition confusion and get the <b>evidence-based facts.</b>
            </IconListItem>
            <IconListItem>
              <b>Delight your family and friends</b> with new recipes. (They’ll beg you for a copy!)
            </IconListItem>
            <IconListItem>
              Amp up your <b>motivation</b> by discovering WHY certain foods are good for you.
            </IconListItem>
            <IconListItem>
              Cruise through the week with <b>more free time</b> — and more energy — than ever.
            </IconListItem>
            <IconListItem>
              <b>Cut out food waste</b> (and that sad feeling of watching food go in the garbage).
            </IconListItem>
            <IconListItem>
              Feel good, knowing you’re doing your part to <b>reduce climate change.</b>
            </IconListItem>
            <IconListItem>
              <b>Fool-proof your kitchen</b> and launch your new healthy lifestyle.
            </IconListItem>
            <IconListItem>
              Enjoy the short-term and long-term benefits of the planet’s most <b>nutrient-dense superfoods,</b> which
              are readily available and often less expensive than prepared meals!
            </IconListItem>
          </IconList>
        </SectionImage>

        <Section id="fda-cta-1" color="gradient-indigo-purple-right">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="10" xl="8">
                <CtaSalesBox />
              </Col>
            </Row>

            <Row>
              <Col>
                <h4 className="text-white text-center mt-5">
                  This is your chance to get the <br className="d-none d-md-block d-xl-none" />
                  most cutting-edge <br className="d-none d-xl-block" />
                  health information <br className="d-none d-md-block d-xl-none" />
                  <i>
                    pulled directly from scientific and <br className="d-none d-xl-block" />
                    medical <br className="d-none d-lg-block d-xl-none" />
                    studies
                  </i>{' '}
                  (NOT opinion or fads).
                </h4>
                <p className="text-white text-center">
                  <i>
                    Some of this information is so new, <br className="d-none d-sm-block d-lg-none" />
                    your doctor might not know about it yet.
                  </i>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="fda-modules" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center">
                  Here’s some of what you’ll discover in the <br className="d-none d-xl-block" />
                  14-Day Plant-Powered Accelerator:
                </h2>
                <ModuleList list={fdaModules} />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="fda-guarantee" color="light-gray">
          <Container>
            <Row>
              <Col>
                <GuaranteeBox title={<>A Total 100%-Money-Back Guarantee</>}>
                  <p>
                    We’re sure you’re going to love the 14-Day Plant-Powered Accelerator, and that you’ll be excited to
                    pass this exciting wisdom on to your friends and loved ones.
                  </p>
                  <p>But if for any reason whatsoever you don’t, we’ll give you every penny back.</p>
                  <p>
                    Plus, for every 14-Day Plant-Powered Accelerator that’s sold, we’ll donate to Trees for the Future
                    so they can plant an organic fruit or nut tree in a low-income community. Our mission at Food
                    Revolution Network is healthy, ethical, sustainable food for all. We care deeply about our planet
                    and donate many thousands of dollars a year to causes that make the world a better place.
                  </p>
                  <p>
                    We believe that a healthier world starts when one person at a time makes a small but significant{' '}
                    shift in the right direction.
                  </p>
                  <p>It’s never too early (or too late) to start!</p>
                </GuaranteeBox>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage id="fda-express" color="white" imgPosition="left" imgUrl={require('static/fda/1289797791.jpg')}>
          <h2 className="section-heading">
            The 14-Day Plant-Powered Accelerator is the “express lane” to a new and{' '}
            <br className="d-none d-sm-block d-xl-none" />
            healthier life.
          </h2>
          <p>We can’t wait to hear about the results and wins you’ll get in just 2 weeks.</p>
          <p>So, if you want those results, jump on this special offer now.</p>
          {!salesFullPrice ? (
            <>
              <p>
                It’s listed on our website for $47, but now’s your chance to get it for less than a fancy green juice at
                the airport.
              </p>
              <p>
                We’re not sure how long we’ll be able to keep offering the 14-Day Plant-Powered Accelerator for just $7.
              </p>
              <p>(It’s a whopping 85% off, after all.)</p>
              <p>That’s the lowest price you’ll ever see.</p>
            </>
          ) : null}
          <p>Just click the button and you’re instantly in.</p>
        </SectionImage>

        <Section id="fda-cta-3" className="section-angle-last" color="gradient-indigo-purple-right" angle="none">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="10" xl="8">
                <CtaSalesBox />
              </Col>
            </Row>
          </Container>
        </Section>
      </div>

      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons floating />
      </div>

      {noExitIntent ? null : <ModalExitFDA version={salesFullPrice ? 'fullPrice' : 'salesPrice'} />}
    </div>
  );
}
